import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import * as _ from 'lodash';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { globalFailure } from '../store/common.actions';
import { getSessionData } from '../store/session/session.selectors';
import { Session } from '../models/session.model';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MiddlewareService {
  sessionHeaders?: Session;

  constructor(private readonly _http: HttpClient, private store: Store) {
    // console.log('%cLoad MiddlewareService', 'color:green');
    store.select(getSessionData).subscribe(_session => {
      //  console.log('%cInject Session MiddlewareService', 'color:orange', _session);
      this.sessionHeaders = _session;
    });
  }

  get<T>(url: string, params: HttpParams | { [param: string]: any } = new HttpParams(), errorWhitelist?: string[] | number[]): Observable<T> {
    return this._http
      .get<T>(`${this.getUrl(url)}`, {
        params
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.store.dispatch(
            globalFailure({ error: err, whitelist: errorWhitelist, method: 'GET', request_id: err.headers?.get('x-request-id') || undefined, requestPayload: this.getPayload(params) })
          );
          return throwError(() => err);
        })
      );
  }

  post<T>(url: string, params: FormData | HttpParams | { [param: string]: any } = new HttpParams(), errorWhitelist?: string[] | number[]): Observable<T> {
    return this._http.post<T>(`${this.getUrl(url)}`, params).pipe(
      catchError((err: HttpErrorResponse) => {
        this.store.dispatch(
          globalFailure({
            error: err,
            whitelist: errorWhitelist,
            method: 'POST',
            request_id: err.headers?.get('x-request-id') || undefined,
            requestPayload: this.getPayload(params)
          })
        );
        return throwError(() => err);
      })
    );
  }

  postHttp<T>(url: string, params: FormData | HttpParams | { [param: string]: any } = new HttpParams(), errorWhitelist?: string[] | number[]): Observable<HttpResponse<T>> {
    return this._http.post<T>(`${this.getUrl(url)}`, params, { observe: 'response' }).pipe(
      catchError((err: HttpErrorResponse) => {
        this.store.dispatch(
          globalFailure({
            error: err,
            whitelist: errorWhitelist,
            method: 'POST',
            request_id: err.headers?.get('x-request-id') || undefined,
            requestPayload: this.getPayload(params)
          })
        );
        return throwError(() => err);
      })
    );
  }

  patch<T>(url: string, params: FormData | HttpParams | { [param: string]: any } = new HttpParams(), errorWhitelist?: string[] | number[]): Observable<T> {
    return this._http.patch<T>(`${this.getUrl(url)}`, params).pipe(
      catchError((err: HttpErrorResponse) => {
        this.store.dispatch(
          globalFailure({ error: err, whitelist: errorWhitelist, method: 'PATCH', request_id: err.headers?.get('x-request-id') || undefined, requestPayload: this.getPayload(params) })
        );
        return throwError(() => err);
      })
    );
  }

  patchHttp<T>(url: string, params: FormData | HttpParams | { [param: string]: any } = new HttpParams(), errorWhitelist?: string[] | number[]): Observable<HttpResponse<T>> {
    return this._http.patch<T>(`${this.getUrl(url)}`, params, { observe: 'response' }).pipe(
      catchError((err: HttpErrorResponse) => {
        this.store.dispatch(
          globalFailure({ error: err, whitelist: errorWhitelist, method: 'PATCH', request_id: err.headers?.get('x-request-id') || undefined, requestPayload: this.getPayload(params) })
        );
        return throwError(() => err);
      })
    );
  }

  delete<T>(url: string, errorWhitelist?: string[] | number[]): Observable<T> {
    return this._http.delete<T>(`${this.getUrl(url)}`).pipe(
      catchError((err: HttpErrorResponse) => {
        this.store.dispatch(globalFailure({ error: err, whitelist: errorWhitelist, method: 'DELETE', request_id: err.headers?.get('x-request-id') || undefined }));
        return throwError(() => err);
      })
    );
  }

  deleteHttp<T>(url: string, errorWhitelist?: string[] | number[]): Observable<HttpResponse<T>> {
    return this._http.delete<T>(`${this.getUrl(url)}`, { observe: 'response' }).pipe(
      catchError((err: HttpErrorResponse) => {
        this.store.dispatch(globalFailure({ error: err, whitelist: errorWhitelist, method: 'DELETE', request_id: err.headers?.get('x-request-id') || undefined }));
        return throwError(() => err);
      })
    );
  }

  download(url: string, errorWhitelist?: string[] | number[]): Observable<any> {
    return this._http
      .get(`${this.getUrl(url)}`, {
        responseType: 'blob'
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.store.dispatch(globalFailure({ error: err, whitelist: errorWhitelist, method: 'GET', request_id: err.headers?.get('x-request-id') || undefined }));
          return throwError(() => err);
        })
      );
  }

  private getPayload(params: FormData | HttpParams | { [param: string]: any }) {
    if (params instanceof HttpParams) {
      return params.toString();
    }
    if (params instanceof FormData) {
      const ret: any = {};
      params.forEach((val, key) => (ret[key] = val));
      return ret;
    }
    return params;
  }


  /**
   * returns the URL or a new URL from endpoint overrides
   */
  private getUrl(url: string) {
    let newUrl = url;
    const path = url.replace(/^http(s?):\/\/[^\/]+/, '');

    if (environment.endpointOverrides && typeof (environment.endpointOverrides) === 'object') {
      _.forEach(environment.endpointOverrides, (_overrideEndpoint, _overridePath) => {

        if (_overridePath.match(/^\/(.*)\/$/) && path.split('?')[0].match(new RegExp(RegExp.$1))) {
          newUrl = _overrideEndpoint;

        } else if (path.split('?')[0] === _overridePath) {
          newUrl = _overrideEndpoint;

        } else {
          return true;
        }

        return false;
      });
    }

    return newUrl;
  }
}
